import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image"; // Import if not already imported
import React from "react";
import ReactPlayer from "react-player"; // Import if not already imported
import MutedButton from "../Global/MutedButton";
import InView from "../InView";

const ImageTextBlock = ({ content, loaded, muted, setMuted, setLoaded }) => {
	return (
		<InView>
			<div className="container flex flex-col md:grid md:grid-cols-12 py-6 md:py-0 md:mt-10 gap-6 md:gap-10">
				<div
					className={`flex flex-col md:pb-10 ${
						content.layout === "video" ? ` md:col-span-4` : ` `
					} ${content.layout === "two-thirds" ? ` md:col-span-5` : ` `} ${
						content.layout === "one-quarter" ? ` md:col-span-8` : ` `
					}${
						content.imageDirection === "image-right"
							? ` md:pr-10 order-1`
							: ` order-2 mt-4 md:mt-0`
					}`}
				>
					<h3 className="max-w-[720px]">{content.title}</h3>
					<div
						className={`mt-3 md:mt-auto ${
							content.layout === "two-thirds"
								? `max-w-[580px]`
								: `max-w-[720px]`
						}`}
					>
						<h2 className="font-serif text-[32px] leading-[1.2] mb-2">
							{content.description}
						</h2>
						{content.link && (
							<Link className="text-sm text-grey-base link" to={content.link}>
								{content.linkText}
							</Link>
						)}
					</div>
				</div>
				<div
					className={`md:pb-10 md:border-grey-default box-border ${
						content.layout === "video" ? ` md:col-span-8` : ` `
					} ${content.layout === "two-thirds" ? ` md:col-span-7` : ` `} ${
						content.layout === "one-quarter" ? ` md:col-span-4` : ` `
					} ${
						content.imageDirection === "image-right"
							? ` order-2 md:pl-10 md:border-l mt-10 md:mt-0`
							: ` order-1 md:pr-10 md:border-r`
					}`}
				>
					{content.media === "image" && content.imageType === "landscape" && (
						<Image
							{...content.image}
							width={1000}
							height={680}
							alt={content.image.alt}
							className={`lazy ` + (loaded && " loaded")}
							onLoad={() => setLoaded(true)}
						/>
					)}
					{content.media === "image" && content.imageType === "portrait" && (
						<Image
							{...content.image}
							width={605}
							height={880}
							alt={content.image.alt}
							className={
								`max-w-[75%] md:max-w-[90%] max-h-[880px] w-auto lazy ` +
								(loaded && " loaded")
							}
							onLoad={() => setLoaded(true)}
						/>
					)}
					{content.media === "video" && (
						<div
							className={`video-wrapper relative lazy ` + (loaded && " loaded")}
						>
							<ReactPlayer
								className="react-player"
								width="100%"
								height="100%"
								playing
								playsinline={true}
								loop
								muted={muted}
								config={{
									file: {
										attributes: {
											autoPlay: true,
											muted: true,
										},
									},
								}}
								url={content.videoUrl}
								onReady={() => setLoaded(true)}
							/>
							<MutedButton muted={muted} setMuted={setMuted} />
						</div>
					)}
				</div>
			</div>
		</InView>
	);
};

export default ImageTextBlock;
