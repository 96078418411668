import { Link } from "gatsby";
import React, { useState } from "react";
import { A11y } from "swiper";
import "swiper/css"; // Ensure Swiper styles are imported
import { Swiper, SwiperSlide } from "swiper/react";
import InView from "../InView"; // Adjust the import path as needed
import RelatedProject from "./RelatedProject";

const RelatedProjects = ({ content }) => {
	const [loaded, setLoaded] = useState(false); // Manage loaded state for images

	return (
		<InView>
			<div>
				<div className="container related-projects">
					<div className="py-6 md:py-10">
						<h2>{content.title || "More projects"}</h2>
						<h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-[120px] md:mb-10 max-w-[680px]">
							{content.tagline ||
								"Creating places that blur the lines between lifestyle, urban living, and design"}
						</h3>
						<Link className="text-sm link mt-2 md:mt-auto" to="/projects">
							View all projects
						</Link>
					</div>
				</div>
				<div className="mx-auto w-full max-w-full recent-projects">
					<Swiper
						modules={[A11y]}
						slidesPerView={1.3}
						speed={1000}
						breakpoints={{ 1024: { slidesPerView: 3 } }}
					>
						{content.projects.map((item, index) => (
							<SwiperSlide key={index}>
								<RelatedProject
									item={item}
									loaded={loaded}
									setLoaded={setLoaded}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</InView>
	);
};

export default RelatedProjects;
