import Image from "gatsby-plugin-sanity-image";
import React from "react";
import ReactPlayer from "react-player/lazy";
import MutedButton from "../Global/MutedButton";
import InView from "../InView";

const CatalogueFullWidth = ({
	content,
	loaded,
	setLoaded,
	muted,
	setMuted,
}) => {
	return (
		<InView>
			<div className="container flex md:grid md:grid-cols-12 my-6 pb-6 md:my-10 md:pb-10 md:mt-10">
				<div className="md:col-span-12">
					{content.media === "image" && (
						<Image
							{...content.image}
							width={1600}
							height={880}
							alt={content.image.alt}
							className={`lazy ${loaded && "loaded"}`}
							onLoad={() => setLoaded(true)}
						/>
					)}
					{content.media === "video" && (
						<div
							className={`video-wrapper relative lazy ${loaded && "loaded"}`}
						>
							<ReactPlayer
								className="react-player"
								width="100%"
								height="100%"
								playing
								playsinline={true}
								loop
								muted={muted}
								config={{
									file: {
										attributes: {
											autoPlay: true,
											muted: true,
										},
									},
								}}
								url={content.videoUrl}
								onReady={() => setLoaded(true)}
							/>
							<MutedButton muted={muted} setMuted={setMuted} />
						</div>
					)}
				</div>
			</div>
		</InView>
	);
};

export default CatalogueFullWidth;
