import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { useState } from "react"; // Import useState

const RelatedProject = ({ item }) => {
	const [loaded, setLoaded] = useState(false); // Initialize loaded state locally

	return (
		<div className="pb-6 lg:pb-10">
			<div className="overflow-hidden">
				<Link to={`/project/${item.slug.current}`}>
					<Image
						{...item.previewImage}
						width={580}
						height={739}
						alt={item.previewImage.alt}
						className={`transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ${
							loaded && "loaded"
						}`}
						onLoad={() => setLoaded(true)} // Update loaded state when the image is loaded
					/>
				</Link>
			</div>
			<div>
				<h4 className="font-serif text-[32px] leading-[1.2] mt-10 mb-4">
					{item.title}
				</h4>
				<Link className="link text-sm" to={`/project/${item.slug.current}`}>
					View project
				</Link>
			</div>
		</div>
	);
};

export default RelatedProject;
