import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React from "react";
import InView from "../InView";

const EnquiryBlock = ({
	content,
	enquiryCTAImage,
	enquiryCTATitle,
	enquiryCTAText,
	salesforceID,
	open,
	setOpen,
	loaded,
	setLoaded,
}) => {
	return (
		<InView>
			<div className="container flex flex-col md:grid md:grid-cols-12 py-6 md:py-0 md:mt-10 gap-6 md:gap-10">
				<div className="md:col-span-4 md:pb-10 md:pr-10 md:border-r md:border-grey-default">
					{content.image ? (
						<Image
							{...content.image}
							width={605}
							height={880}
							alt={content.tagline}
							className={`max-w-[75%] md:max-w-[100%] max-h-[880px] w-auto lazy ${
								loaded && "loaded"
							}`}
							onLoad={() => setLoaded(true)}
						/>
					) : (
						enquiryCTAImage ? <Image
							{...enquiryCTAImage}
							width={605}
							height={880}
							alt=" "
							className={`max-w-[75%] md:max-w-[100%] max-h-[880px] w-auto lazy ${
								loaded && "loaded"
							}`}
							onLoad={() => setLoaded(true)}
						/>
						: ''
					)}
				</div>
				<div className="md:col-span-8 md:pb-10 flex flex-col">
					<h2>{content.title || enquiryCTATitle}</h2>
					<h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-auto md:mb-10 max-w-[680px]">
						{content.tagline || enquiryCTAText}
					</h3>
					{salesforceID && 1==2 ? (
						<button
							type="button"
							className="link text-[#1B1B1B] text-[14px]"
							onClick={() => setOpen(!open)}
						>
							Enquire now
						</button>
					) : (
						<Link to="/contact-us" className="link text-[#1B1B1B] text-[14px]">
							{" "}
							{/* Replace "#" with actual link */}
							Enquire now
						</Link>
					)}
				</div>
			</div>
		</InView>
	);
};

export default EnquiryBlock;
