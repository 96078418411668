import { Link } from "gatsby";
import React, { useState } from "react";
import CountUp from "react-countup";
import PortableText from "react-portable-text"; // Adjust the import path as needed
import VisibilitySensor from "react-visibility-sensor";
import InView from "../InView"; // Adjust the import path as needed

const GlanceBlock = ({ content }) => {
	const [isActive, setIsActive] = useState(true);

	const Detail = ({ item }) => (
		<li className="text-[13px]">
			<h3 className="text-[#666] block mb-4">{item.title}</h3>
			{item.description}
		</li>
	);

	const Stat = ({ item }) => (
		<div>
			<CountUp
				start={isActive ? 0 : item.amount}
				separator={item.separator || ``}
				end={item.amount}
				duration={4}
				redraw={true}
				suffix={item.suffix || ``}
				onEnd={() => setIsActive(false)}
			>
				{({ countUpRef, start }) => (
					<VisibilitySensor onChange={start} delayedCall>
						<h3 className="text-[32px] leading-[32px] xl:text-[52px] xl:leading-[52px] font-normal">
							<span ref={countUpRef} />
						</h3>
					</VisibilitySensor>
				)}
			</CountUp>
			<h4>{item.title}</h4>
		</div>
	);
	return (
		<InView>
			<div className="container grid grid-cols-12 gap-6 md:gap-10 py-6 md:py-0 md:mt-10">
				<div className="md:sticky md:h-full md:top-[81px] col-span-12 md:col-span-3 md:pr-10 md:pb-10 md:border-r md:border-grey-default box-border">
					<h2>At a glance</h2>
				</div>
				<div className="col-span-12 md:col-span-9 md:pb-10 md:grid md:grid-cols-2 md:gap-10">
					<div>
						<ul className="grid grid-cols-2 gap-6 md:gap-10">
							{content.details.map((item, index) => (
								<Detail item={item} key={index} />
							))}
						</ul>
						{content._rawAwards && (
							<div className="mt-10">
								<h3 className="text-[13px] text-[#666]">
									Awards and Nominations
								</h3>
								<PortableText
									className="text-[13px] leading-[24px] text-[#000] mt-4"
									content={content._rawAwards}
									serializers={{
										internalLink: ({ reference, children }) => {
											const href = `/${reference._type}/${reference.slug.current}`;
											return <Link to={href}>{children}</Link>;
										},
										link: ({ href, blank, children }) => {
											return (
												<a
													href={href}
													target={blank ? "_blank" : "_self"}
													rel={blank ? "noreferrer" : undefined}
												>
													{children}
												</a>
											);
										},
									}}
								/>
							</div>
						)}
					</div>
					<div className="pt-6 md:pt-0">
						<div className="grid grid-cols-2 gap-6 md:gap-10">
							{content.stats.map((item, index) => (
								<Stat item={item} key={index} />
							))}
						</div>
					</div>
				</div>
			</div>
		</InView>
	);
};

export default GlanceBlock;
