import React from "react";
import InView from "../InView"; // Adjust the import path as needed

const MediaAwardsBlock = ({ content }) => {
	const MediaAward = ({ item }) => (
		<li className="flex flex-row my-2">
			{item.link ? (
				<a
					href={item.link}
					className="flex w-full flex-row md:border-b md:border-transparent md:box-border transition-all duration-200 ease-in-out hover:border-grey-default md:pl-10"
					target="_blank"
					rel="noreferrer"
				>
					<span className="w-[15%] lg:w-20 inline-flex">{item.year}</span>
					<span className="w-[20%] lg:w-28 inline-flex capitalize">
						{item.detailType}
					</span>
					<span className="w-[65%] linked-award lg:w-auto">{item.title}</span>
				</a>
			) : (
				<>
					<span className="w-[15%] lg:w-20 inline-flex">{item.year}</span>
					<span className="w-[20%] lg:w-28 inline-flex capitalize">
						{item.detailType}
					</span>
					<span className="w-[65%] lg:w-auto">{item.title}</span>
				</>
			)}
		</li>
	);

	return (
		<InView>
			<div className="container flex flex-col py-5 md:py-0 md:grid md:grid-cols-12 md:gap-10 md:mt-10">
				<div className="md:col-span-4 md:pb-10 md:border-r md:border-grey-default box-border">
					<h2>Selected media & awards</h2>
				</div>
				<div className="mt-5 md:mt-0 md:col-span-8 md:pb-[80px]">
					<ul className="text-sm -my-2 md:mx-[-30px]">
						{content.items.map((item, index) => (
							<MediaAward item={item} key={index} />
						))}
					</ul>
				</div>
			</div>
		</InView>
	);
};

export default MediaAwardsBlock;
