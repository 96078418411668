import Image from "gatsby-plugin-sanity-image";
import React, { useState } from "react";
import { A11y, Autoplay, Navigation } from "swiper";
import "swiper/css"; // Ensure Swiper styles are imported
import { Swiper, SwiperSlide } from "swiper/react";
import InView from "../InView"; // Adjust the import path as needed

const TimelineBlock = ({ content }) => {
	const [loaded, setLoaded] = useState(false);

	// Inner component or logic for TimelineSlideContent
	const TimelineSlideContent = ({ item }) => (
		<Image
			{...item}
			height={880}
			className={`max-h-[260px] h-auto md:max-h-[540px] xl:max-h-[700px] max-w-[100%] w-auto pr-6 md:pr-10 lazy ${
				loaded && "loaded"
			}`}
			onLoad={() => setLoaded(true)}
			alt=" "
		/>
	);

	return (
		<InView>
			<div className="pl-5 md:pl-10 flex flex-col md:grid md:grid-cols-12 my-6 md:my-10 pb-6 md:pb-10">
				<div className="flex flex-col md:col-span-4 pr-6 md:pr-10">
					{content.title && <h3 className="mb-2 md:my-0">{content.title}</h3>}
					<div className="md:mt-auto">
						{content.introduction && (
							<h2 className="font-serif text-[32px] leading-[1.2] max-w-[680px]">
								{content.introduction}
							</h2>
						)}
					</div>
				</div>
				<div className="mt-6 md:my-0 md:col-span-8">
					<Swiper
						className="slider--overflow single-project"
						modules={[Navigation, Autoplay, A11y]}
						slidesPerView={"auto"}
						navigation
						speed={1000}
					>
						{content.slides.map((item, index) => (
							<SwiperSlide className="w-auto" key={index}>
								<TimelineSlideContent item={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</InView>
	);
};

export default TimelineBlock;
