import Image from "gatsby-plugin-sanity-image"; // Ensure this is imported if used
import React from "react";
import ReactPlayer from "react-player/lazy"; // Ensure you're using the correct import for ReactPlayer
import MutedButton from "../Global/MutedButton"; // Adjust the import path as needed
import InView from "../InView"; // Adjust the import path as needed

const CatalogueThreeQuarter = ({
	content,
	loaded,
	setLoaded,
	muted,
	setMuted,
}) => {
	const alignmentClass =
		content.alignment === "middle"
			? "md:col-start-3"
			: content.alignment === "right"
			? "md:col-start-5"
			: "";

	return (
		<InView>
			<div className="container flex md:grid md:grid-cols-12 my-6 pb-6 md:my-10 md:pb-10 gap-6 md:gap-10">
				<div className={`md:col-span-8 ${alignmentClass}`}>
					{content.media === "image" && (
						<Image
							{...content.mainImage}
							width={1200}
							height={800}
							alt={content.mainImage.alt}
							className={`lazy ${loaded && "loaded"}`}
							onLoad={() => setLoaded(true)}
						/>
					)}
					{content.media === "video" && (
						<div
							className={`video-wrapper relative lazy ${loaded && "loaded"}`}
						>
							<ReactPlayer
								className="react-player"
								width="100%"
								height="100%"
								playing
								playsinline={true}
								loop
								muted={muted}
								config={{
									file: {
										attributes: {
											autoPlay: true,
											muted: true,
										},
									},
								}}
								url={content.videoUrl}
							/>
							<MutedButton muted={muted} setMuted={setMuted} />
						</div>
					)}
				</div>
			</div>
		</InView>
	);
};

export default CatalogueThreeQuarter;
