import { Link } from "gatsby";
import React from "react";
import PortableText from "react-portable-text"; // Ensure this is imported if used
import InView from "../InView"; // Adjust the import path as needed

const CatalogueText = ({ content }) => {
	const alignmentClass =
		content.alignment === "middle"
			? "md:col-start-5"
			: content.alignment === "right"
			? "md:col-start-8"
			: "";

	return (
		<InView>
			<div className="container flex md:grid md:grid-cols-12 pt-6 pb-[36px] md:pb-[120px] md:pt-[80px]">
				<div className={`copy copy--container md:col-span-5 ${alignmentClass}`}>
					{content._rawBasic && (
						<PortableText
							content={content._rawBasic}
							serializers={{
								internalLink: ({ reference, children }) => {
									const href = `/${reference._type}/${reference.slug.current}`;
									return <Link to={href}>{children}</Link>;
								},
								link: ({ href, blank, children }) => {
									return (
										<a
											href={href}
											target={blank ? "_blank" : "_self"}
											rel={blank ? "noreferrer" : undefined}
										>
											{children}
										</a>
									);
								},
							}}
						/>
					)}
				</div>
			</div>
		</InView>
	);
};

export default CatalogueText;
