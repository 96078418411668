import Image from "gatsby-plugin-sanity-image"; // Ensure this is imported
import React from "react";
import InView from "../InView"; // Adjust the import path as needed

const CatalogueTwoPortrait = ({ content, loaded, setLoaded }) => {
	const leftImageAlignmentClass =
		content.alignment === "middle"
			? "md:col-start-3"
			: content.alignment === "right"
			? "md:col-start-5"
			: "";
	const rightImageAlignmentClass =
		content.alignment === "middle"
			? "md:col-start-7" // Adjust if needed for right image alignment
			: content.alignment === "right"
			? "md:col-start-9"
			: ""; // Adjust if needed for right image alignment

	return (
		<InView>
			<div className="container grid grid-cols-12 my-6 pb-6 md:my-10 md:pb-10 gap-6 md:gap-10">
				<div className={`col-span-6 md:col-span-4 ${leftImageAlignmentClass}`}>
					{content.leftImage && (
						<Image
							{...content.leftImage}
							width={605}
							height={880}
							alt={content.leftImage.alt}
							className={`lazy ${loaded && "loaded"}`}
							onLoad={() => setLoaded(true)}
						/>
					)}
				</div>
				<div className={`col-span-6 md:col-span-4 ${rightImageAlignmentClass}`}>
					{content.rightImage && (
						<Image
							{...content.rightImage}
							width={605}
							height={880}
							alt={content.rightImage.alt}
							className={`lazy ${loaded && "loaded"}`}
							onLoad={() => setLoaded(true)}
						/>
					)}
				</div>
			</div>
		</InView>
	);
};

export default CatalogueTwoPortrait;
